<template>
    <v-app>
      <v-dialog
        v-model="dialogLoading"
        hide-overlay
        v-if="loading"
        persistent
        width="300"
      >
        <v-card color="primary" dark>
          <v-card-text>
            <h2 class="white--text">Loading</h2>
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
  
      <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>
  
      <div id="exportContent">
        <div class="page">
       
          <div align="center">
            <img
            width="80"
                height="80"
              src="ovec.png"
            />
            <br />
            <div class="head">
              ใบสมัคร <br>

              {{ temEmIDReGis }}
            </div>
          
            
           
          <!-- New Page -->
          <p style="page-break-before: always;">&nbsp;</p>
          <!-- 2 -->   
              
        </div>
      </div>
      </div>
    </v-app>
  </template>
  
  <script>
  export default {
    name: "App",
    data: () => ({
      ApiKey: "HRvec2021",     
    }),
  
    async mounted() {
   
    },
  
    methods: {
    
  
      Export2Doc(element, filename) {
        var preHtml =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
        var postHtml = "</body></html>";
        var html =
          preHtml + document.getElementById(element).innerHTML + postHtml;
  
        var blob = new Blob(["\ufeff", html], {
          type: "application/msword"
        });
  
        // Specify link url
        var url =
          "data:application/vnd.ms-word;charset=utf-16," +
          encodeURIComponent(html);
  
        // Specify file name
        filename = filename ? filename + ".doc" : "document.doc";
  
        // Create download link element
        var downloadLink = document.createElement("a");
  
        document.body.appendChild(downloadLink);
  
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          // Create a link to the file
          downloadLink.href = url;
  
          // Setting the file name
          downloadLink.download = filename;
  
          //triggering the function
          downloadLink.click();
        }
  
        document.body.removeChild(downloadLink);
      }
    },
    computed: {
        temEmIDReGis() {
        let result = window.location.href;
        let split_s = result.split("/");
        return split_s[5];
      },
  
   
     
    },
    props: {
      source: String
    }
  };
  </script>
  <style>
  body {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16px;
    font-weight: 250;
  }
  
  .u {
    border-bottom: 1px dotted #000;
    text-decoration: none;
  }
  u {
    border-bottom: 1px dotted #000;
    text-decoration: none;
  }
  
  * {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  
  .table,
  .th,
  .td {
    border-bottom: 0.5px solid black;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-collapse: collapse;
  }
  
  .text_j {
    border-collapse: collapse;
    text-align: justify;
    text-justify: inter-word;
    line-height: 1;
  }
  .text_l {
    border-collapse: collapse;
    text-align: left;
    text-justify: inter-word;
    line-height: 1;
  }
  
  tfoot tr:hover {
    background-color: initial;
  }
  
  tbody tr:hover {
    background-color: inherit;
  }
  
  td,
  th {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
  }
  
  tfoot td {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-weight: bold;
  }
  
  #verticaltext {
    writing-mode: tb-rl;
    filter: flipv fliph;
    -webkit-transform: rotate(-90deg);
    white-space: nowrap;
    display: block;
    overflow: hidden;
    padding: 0;
  }
  
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    width: 21cm;
    min-height: 29.7cm;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-top: 0cm;
    margin-right: auto;
    margin-bottom: 0cm;
    margin-left: auto;
    padding-top: 1.5cm;
    padding-right: 1.5cm;
    padding-bottom: 1.5cm;
    padding-left: 2cm;
  }
  
  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 22pt;
    font-weight: bold;
    line-height: 1.5;
  }
  
  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }
  
  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }
  
  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }
  
  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
    font-weight: bold;
  }
  
  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 12pt;
  }
  
  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }
  
  .largerCheckbox {
    width: 30px;
    height: 30px;
    color: blue;
  }
  .td_line {
    border-bottom: 1pt dotted black;
  }
  /*.subpage {
        height: 247mm;
      }*/
  
  .BlackBold10 {
    font-weight: bold;
  }
  
  #content {
    display: table;
  }
  
  #pageFooter {
    display: table-footer-group;
  }
  
  #pageFooter:after {
    counter-increment: page;
    content: counter(page);
  }
  
  @media print {
    .page {
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      padding: initial;
      page-break-after: always;
    }
  
    .head {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 18pt;
      font-weight: bold;
    }
  
    .regular12 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
    }
  
    .regular16 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
    }
  
    .bold16 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
      font-weight: bold;
    }
  
    .blod12 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
      font-weight: bold;
    }
  
    .blackRegula10 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 14pt;
    }
  
    .blackBold18 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 18pt;
      font-weight: bold;
    }
  
    /* .head {
          font-size: 18px;
          font-weight: bold;
        }
        .regular12 {
          font-size: 12px;
        }
        .blod12 {
          font-size: 12px;
          font-weight: bold;
        }
        .blackRegula8 {
          font-size: 8px;
        } */
    .noprint {
      display: none;
    }
  
    .gap-10 { 
                  width:100%; 
                  height:10px; 
              } 
              .gap-20 { 
                  width:100%; 
                  height:20px; 
              } 
              .gap-30 { 
                  width:100%; 
                  height:30px; 
              } 
  
  }
  </style>
  